const Section = (data) => {
  if (data.type === 'Hero' || data.type === 'Marquee') return data

  const {
    type,
    inverted,
    indexLabel = null,
    title,
    subtitle,
    description,
    cta,
    descriptionStyle,
    ...otherProps
  } = data


  const theme =  inverted ? 'inverted' : 'default'
  const sectionGeneral = {
    theme,
    type
  }

  if (!!indexLabel) {
    Object.assign(sectionGeneral, {
      'data-theme': theme,
      'data-section-index': indexLabel.toLowerCase().replace(' ', '-')
    })
  }

  const sectionHeader = {
    title,
    subtitle,
    description,
    cta,
    theme,
    descriptionStyle,
  }

  const sectionContent = {
    type,
    ...otherProps
  }

  const normalizedData = {
    sectionHeader,
    sectionContent,
    sectionGeneral
  }

  return normalizedData
}

export default Section
