import { styled } from 'config/stitches.config'

export const StyledSectionBuilder = styled('main', {
  variants: {
    darkMode: {
      true: { backgroundColor: '$black', },
      false: { backgroundColor: '$white', },
    }
  },
  defaultVariants: { darkMode: false }

})
