import React, { useContext } from 'react'
import { GeneralContext } from 'contexts/General'
import * as Sections from 'sections'
import SectionModel from 'services/models/Section'

import Section from '@/Section'


import { StyledSectionBuilder } from './styles'

const SectionBuilder = ({ sections }) => {
  const { page } = useContext(GeneralContext)

  const renderComponent = (component, i) => {
    const Component = Sections[component.type]

    // console.log('[SECTION BUILDER] - ', component)
    const componentData = SectionModel(component)

    const fallBack = process.env.NEXT_PUBLIC_ENV === 'develop' ? component.type : <></>

    return Component ? (
      <Section
        key={`${component.type.toLowerCase()}_${i}`}
        className={component.type.toLowerCase()}
        darkMode={page.pageDarkTheme}
        {...componentData.sectionGeneral}
      >
        <Component darkMode={page.pageDarkTheme} {...componentData} />
      </Section>
    ) : fallBack
  }


  return (
    <StyledSectionBuilder
      id="MainApp"
      darkMode={page.pageDarkTheme}
    >
      {[...sections.map(renderComponent)]}
    </StyledSectionBuilder>
  )
}

export default SectionBuilder
