import { styled } from 'config/stitches.config'


const Section = styled('section', {
  variants: {
    theme: {
      default: { padding: '0' },

      inverted: {
        padding: '$1',

        '@md': { padding: '$3', }
      }
    },
    darkMode: {
      false: { color: '$black' },
      true: { color: '$white' }
    }
  },
  defaultVariants: {
    theme: 'default',
    darkMode: false
  }
})

export default Section
